<template>
    <TableLayout :permissions="['renthouse:rhworkorder:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="房源标题" prop="houseTitle">
          <el-input v-model="searchForm.houseTitle" placeholder="请输入房源标题" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="房东手机号" prop="landlordPhone">
          <el-input v-model="searchForm.landlordPhone" placeholder="请输入房东手机号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="租客手机号" prop="tenantPhone">
          <el-input v-model="searchForm.tenantPhone" placeholder="请输入租客手机号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="工单状态" prop="workStatus">
          <el-select v-model="searchForm.workStatus" placeholder="请选择" @change="search">
            <el-option label="未处理" :value="1"></el-option>
            <el-option label="处理中" :value="2"></el-option>
            <el-option label="已完结" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['renthouse:rhworkorder:delete']">
          <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhworkorder:delete']">删除</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="houseTitle" label="房源标题" min-width="100px"></el-table-column>
          <el-table-column prop="landlordName" label="房东姓名" min-width="100px"></el-table-column>
          <el-table-column prop="landlordPhone" label="房东手机号" min-width="100px"></el-table-column>
          <el-table-column prop="tenantName" label="租客姓名" min-width="100px"></el-table-column>
          <el-table-column prop="tenantPhone" label="租客手机号" min-width="100px"></el-table-column>
          <el-table-column prop="workStatus" label="工单状态" min-width="100px">
            <template slot-scope="{row}">
              <span v-if="row.workStatus === 1">未处理</span>
              <span v-if="row.workStatus === 2">处理中</span>
              <span v-if="row.workStatus === 3">已完结</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateUserName" label="更新人账号" min-width="100px"></el-table-column>
          <el-table-column prop="updateTime" label="更新时间" min-width="100px"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
          <el-table-column
            v-if="containPermissions(['renthouse:rhworkorder:update', 'renthouse:rhworkorder:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="updateStatus(row)" v-if="[1,2].includes(row.workStatus)">状态扭转</el-button>
              <el-button type="text" @click="deleteById(row)" v-permissions="['renthouse:rhworkorder:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
    </TableLayout>
  </template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { updateById } from '@/api/rentHouse/rhWorkOrder'
export default {
  name: 'RhWorkOrder',
  extends: BaseTable,
  components: { TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        houseTitle: '',
        landlordPhone: '',
        tenantPhone: '',
        workStatus: ''
      }
    }
  },
  created () {
    this.config({
      module: '系统工单表',
      api: '/rentHouse/rhWorkOrder',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    // 扭转状态
    updateStatus (e) {
      let info = ''
      let workStatus = ''
      if (e.workStatus === 1) {
        info = '确认将状态扭转为处理中吗？'
        workStatus = 2
      } else if (e.workStatus === 2) {
        info = '确认将状态扭转为已完结吗？'
        workStatus = 3
      }
      this.$confirm(info, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateById({ id: e.id, workStatus })
          .then(res => {
            this.search()
          })
      }).catch(() => {

      })
    }
  }
}
</script>
