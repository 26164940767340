<template>
  <TableLayout :permissions="['communityarticle:communityarticle:query']" style="padding: 30px;box-sizing: border-box;">
    <el-row :gutter="20" style="background: #fff;margin: 0;">
      <el-col :span="12" style="border-right: 1px solid #ebeef5;">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :on-change="handleFileChange"
          :auto-upload="false"
          :show-file-list="false"
        >
          <div style="width: 100%;">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖拽文件上传或 <em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只接收Docx 文件</div>
          </div>
        </el-upload>
        <el-table :data="textList" style="width: 100%" @row-click="handleFileSelect">
          <el-table-column prop="title" label="文件名"></el-table-column>
          <el-table-column prop="pic" label="文件多媒体资源"></el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <div v-if="docxContent" style="height: 100%;overflow: auto;width: 100%;">
          <div v-html="docxContent"></div>
        </div>
      </el-col>
    </el-row>
  </TableLayout>
</template>

<script>
import * as mammoth from 'mammoth'
import TableLayout from '@/layouts/TableLayout'

const getExtensionFromBase64 = (base64String) => {
  const match = base64String.match(/^data:(image\/(\w+));base64,/)
  return match ? `.${match[2]}` : null
}

export default {
  data () {
    return {
      textList: [],
      docxFile: {},
      docxContent: '',
      selectedFile: null
    }
  },
  components: {
    TableLayout
  },
  methods: {
    handleFileChange (file, fileList) {
      this.handleFileSelect(file)
    },
    handleFileSelect (file) {
      this.selectedFile = file.uid
      const reader = new FileReader()
      reader.onload = async (event) => {
        const arrayBuffer = event.target.result
        const html = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, {
          ignoreEmptyParagraphs: false
        })
        const pushObj = this.pasreDom(html.value)
        this.fileList.push(pushObj)
      }
      reader.readAsArrayBuffer(file.raw)
    },
    pasreDom (html) {
      const parser = new DOMParser()
      /** @type {Document} */
      const Dom = parser.parseFromString(html, 'text/html')
      const parseDomList = Dom.body.childNodes
      const pushObj = {
        title: '',
        content: '',
        imgUrl: []
      }
      const context = []
      parseDomList.forEach((item, itemIndex) => {
        if (itemIndex === 0) {
          // title
          pushObj.title = item.textContent
          return
        }
        if (item.nodeName === 'P') {
          context.push(item.textContent + '\t\n')
        }
      })

      const imgUrl = []
      Dom.querySelectorAll('img').forEach(item => {
        // item.src = base 64, Base64 to File
        imgUrl.push({ src: item.src, fileExec: getExtensionFromBase64(item.src) })
      })
      pushObj.content = context.join('')
      pushObj.imgUrl = imgUrl
      return pushObj
    }
  }
}
</script>

<style scoped>
.upload-demo {
  width: 100%;
}
</style>
