<template>
    <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="类别" prop="category">
            <el-select v-model="form.category" placeholder="请选择" v-trim>
                <el-option
                    v-for="(item, itemIndex) in categoryList"
                    :key="itemIndex"
                    :label="item.label"
                    :value="item.code">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="租房群名称" prop="rentalGroupName">
          <el-input v-model="form.rentalGroupName" placeholder="请输入租房群名称" v-trim/>
        </el-form-item>
        <el-form-item label="标签" prop="label">
          <el-input v-model="form.label" placeholder="请输入标签，多个用英文逗号隔开" v-trim/>
        </el-form-item>
        <el-form-item label="租房群二维码" prop="rentalGroupNo">
          <UploadAvatarImage :file="file1" @uploadSuccess="uploadSuccess1" />
        </el-form-item>
        <el-form-item label="图片" prop="photo">
            <UploadAvatarImage :file="file" @uploadSuccess="uploadSuccess" />
        </el-form-item>
      </el-form>
    </GlobalWindow>
  </template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import UploadAvatarImage from '@/components/common/UploadAvatarImage'
import { findByDictCode } from '@/api/system/common'
export default {
  name: 'OperaRhRentalGroupWindow',
  extends: BaseOpera,
  components: { GlobalWindow, UploadAvatarImage },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        rentalGroupName: '',
        rentalGroupNo: '',
        category: '',
        photo: '',
        label: ''
      },
      // 验证规则
      rules: {
        category: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        rentalGroupName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        rentalGroupNo: [
          { required: true, message: '请上传', trigger: 'blur' }
        ],
        photo: [
          { required: true, message: '请上传', trigger: 'blur' }
        ]
      },
      file: {
        imgurl: '',
        imgurlfull: ''
      },
      file1: {
        imgurl: '',
        imgurlfull: ''
      },
      categoryList: []
    }
  },
  created () {
    this.config({
      api: '/rentHouse/rhRentalGroup',
      'field.id': 'id'
    })
    this.getData()
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.file.imgurl = this.form.photo
        this.file.imgurlfull = process.env.VUE_APP_API_IMG + this.form.photo

        this.file1.imgurl = this.form.rentalGroupNo
        this.file1.imgurlfull = process.env.VUE_APP_API_IMG + this.form.rentalGroupNo
      })
    },
    getData () {
      findByDictCode({ dictCode: 'crowd' })
        .then(res => {
          this.categoryList = res
        })
    },
    uploadSuccess (res) {
      this.form.photo = res.imgurl
      this.file.imgurlfull = res.imgurlfull
    },
    uploadSuccess1 (res) {
      this.form.rentalGroupNo = res.imgurl
      this.file1.imgurlfull = res.imgurlfull
    }
  }
}
</script>
