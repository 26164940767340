<template>
  <TableLayout :permissions="['renthouse:rhusercode:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="searchForm.userName" placeholder="请输入用户名" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="searchForm.phone" placeholder="请输入手机号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="积分类型" prop="type">
        <el-select v-model="searchForm.type" @change="search" placeholder="请选择积分类型">
          <el-option label="邀请注册" :value="1"></el-option>
          <el-option label="扫码积分" :value="2"></el-option>
          <el-option label="身份证扫码" :value="3"></el-option>
          <el-option label="聊天积分" :value="4"></el-option>
          <el-option label="发布求租" :value="5"></el-option>
          <el-option label="查看房源详情" :value="6"></el-option>
          <el-option label="收藏房源" :value="8"></el-option>
          <el-option label="（房东）看房预约" :value="9"></el-option>
          <el-option label="（房客）看房预约" :value="10"></el-option>
          <el-option label="电子签约" :value="11"></el-option>
          <el-option label="点赞积分" :value="12"></el-option>
          <el-option label="微信分享" :value="13"></el-option>
          <el-option label="会员充值" :value="15"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="userName" label="用户名称" min-width="100px"></el-table-column>
        <el-table-column prop="mobile" label="手机号" min-width="100px"></el-table-column>
        <el-table-column prop="totalPoints" label="积分数量" min-width="100px"></el-table-column>
        <el-table-column prop="totalAmount" label="返佣金额" min-width="100px"></el-table-column>
        <el-table-column
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.OperaRhUserCode.open('积分/返佣明细', row)">查看详细</el-button>
            <el-button type="text" @click="editCode(row, 2)">清零积分</el-button>
            <el-button type="text" @click="editCode(row, 1)">清零分佣</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
      <OperaRhUserCode ref="OperaRhUserCode" />
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaRhUserCode from '@/components/rentHouse/OperaRhUserCode'
import { dataClear } from '@/api/rentHouse/rhUserCode'
export default {
  name: 'RhUserCode',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaRhUserCode },
  data () {
    return {
      // 搜索
      searchForm: {
        userName: '',
        phone: '',
        type: ''
      }
    }
  },
  created () {
    this.config({
      module: '用户积分记录表',
      api: '/rentHouse/rhUserCode',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    editCode (row, type) {
      this.$confirm(`确定清除当前用户${type === 1 ? '返佣' : '积分'}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataClear({ userId: row.addUserId, delFlag: type })
          .then(res => {
            this.search()
            this.$message.success('清零成功！')
          })
      }).catch(() => {

      })
    }
  }
}
</script>
