import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/renthouse/rhUserCode/page', data, {
    trim: true
  })
}

// 查询
export function invitationListOneself (data) {
  return request.post('/renthouse/rhUserCode/invitationListOneself', data, {
    trim: true
  })
}

// 返佣积分数据清空
export function dataClear (data) {
  return request.post('/renthouse/rhUserCode/dataClear', data)
}

// 删除积分的明细信息
export function dataDelete (data) {
  return request.post('/renthouse/rhUserCode/dataDelete', data)
}
