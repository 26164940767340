import request from '@/utils/request'

// 获取图片验证码
export function getCaptcha () {
  return request.get('/common/captcha')
}

// 下载本地文件
export function downloadLocalFile (params) {
  return request.get('/resource/local/download', {
    params,
    download: true
  })
}

// 根据密码登录
export function loginByPassword (data) {
  return request.post('/system/login', data)
}

// 根据密码登录
export function upload (data) {
  return request.post('/upload/image', data)
}

// 登出
export function logout (data) {
  return request.post('/system/logout', data)
}

// 修改密码
export function updatePwd (data) {
  return request.post('/system/updatePwd', data)
}

// 获取已登录的用户信息
export function getUserInfo () {
  return request.get('/system/getUserInfo', {
    autoLogin: false
  })
}

// 获取字典值
export function findByDictCode (params) {
  return request.get('/system/dictData/findByDictCode', { params })
}

// 获取周边地铁
export function getSubwayByTx (boundary) {
  return request.get(`/pc/rhHouse/getSubwayByTx?requestUrl=ws/place/v1/search?key=GNCBZ-V4RLI-4WFGV-U5U5K-FLP67-HCBGJ%26keyword=地铁%26boundary=${boundary}`)
}
