<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="文章标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入文章标题" v-trim/>
      </el-form-item>

      <el-form-item label="所属版块" prop="tagForSector" >
        <el-select v-model="form.tagForSector" placeholder="请选择所属板块" @change="changeSector">
          <el-option
            v-for="(item,itemIndex) in tagList"
            :key="itemIndex"
            :label="item.label"
            :value="Number(item.code)">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属标签" prop="tagList">
        <el-select
          :value="tagListMap"
          placeholder="请选择所属标签"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="remoteMethod"
          :loading="tagLoading"
          @change="setTagList"
        >
          <el-option
            v-for="(item,itemIndex) in textTagList"
            :key="itemIndex"
            :label="item.tagName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="文章状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择文章状态">
          <el-option
            v-for="(item,itemIndex) in statusList"
            :key="itemIndex"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属用户" prop="forUserId">
        <el-select
          v-model="form.forUserId"
          placeholder="请选择所属用户"
          filterable
          remote
          reserve-keyword
          :remote-method="getUserList"
         @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in forUserList"
            :key="itemIndex"
            :label="item.mobile"
            :value="item.id">
            <div style="display: flex;align-items: center;">
              <el-avatar shape="circle" style="width:50px;height: 50px;" fit="cover" :src="imgUrl + item.avatar"></el-avatar>
              <div style="padding-left: 8px;">
                <span><b>{{ item.username }}</b></span>
                <div>手机: {{ item.mobile }}</div>
              </div>

            </div>
          </el-option>
         </el-select>
      </el-form-item>

      <el-form-item label="文章内容" prop="context">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 12}" v-model="form.context" placeholder="请输入文章内容" v-trim/>
      </el-form-item>

      <el-form-item label="文章图片,最多上传3张" prop="fileList">
        <el-upload
          :action="base"
          :limit="6"
          :before-upload="beforeUpluad"
          :on-exceed="onExceed"
          :file-list="fileList"
          list-type="picture-card"
          :on-success="onSuccess"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList } from '../../api/rentHouse/communityArticleTag'
import { fetchList as fetchListUser } from '../../api/system/user'
export default {
  name: 'OperaCommunityArticleWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  props: {
    tagList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        title: '',
        context: '',
        tagList: [],
        tagForSector: '',
        pic: '',
        status: '',
        forUserId: ''
      },
      imgUrl: process.env.VUE_APP_API_IMG,
      base: process.env.VUE_APP_API_PREFIX + '/upload/image',
      base1: process.env.VUE_APP_API_PREFIX + '/upload/attach',
      fileList: [],
      forUserList: [],
      textTagList: [],
      tagLoading: false,
      statusList: [
        { label: '待审核', value: 1 },
        { label: '已审核', value: 2 },
        { label: '已驳回', value: 3 }
      ],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/communityArticle',
      'field.id': 'id'
    })

    const extendsOpen = this.open.bind(this)

    this.open = (title, target) => {
      const picList = (target && target.pic) || ''

      this.fileList = picList ? picList.split(',').map(item => {
        return {
          accessUri: item,
          url: process.env.VUE_APP_API_IMG + item
        }
      }) : []

      extendsOpen(title, target)

      this.$nextTick(() => {
        this.remoteMethod()
        this.getUserList()
      })
    }
  },
  computed: {
    tagListMap () {
      return this.form.tagList.map(item => {
        return Number(item.id)
      })
    }
  },
  methods: {
    async getUserList (keyword) {
      const res = await fetchListUser({
        page: 1,
        capacity: 50,
        model: {
          username: '',
          nickName: '',
          realname: '',
          mobile: keyword
        },
        sorts: [{ property: 'CREATE_TIME', direction: 'DESC' }]
      })

      this.forUserList = res.records
    },
    changeSector (event) {
      this.textTagList = []
      this.form.tagList = []
      this.form.tagForSector = event
      this.remoteMethod()
    },
    async setTagList (event) {
      this.form.tagList = event.map(item => {
        return {
          id: item
        }
      })
    },
    async remoteMethod (query) {
      this.tagLoading = true
      const resData = await fetchList({ page: 1, capacity: 50, model: { id: '', tagName: query, tagForSector: this.form.tagForSector }, sorts: null })
      this.tagLoading = false
      this.textTagList = resData.records
    },
    handleRemove (a, b) {
      this.fileList.forEach((item, index) => {
        if (item.accessUri === a.accessUri) {
          this.fileList.splice(index, 1)
        }
      })
      const arr = b.map(item => item.accessUri)
      this.form.pic = arr.join(',')
    },
    onSuccess (res) {
      this.fileList.push({ ...res.data, url: process.env.VUE_APP_API_IMG + res.data.accessUri })
      const arr = []
      this.fileList.forEach(item => {
        arr.push(item.accessUri)
      })
      this.form.pic = arr.join(',')
    },
    onExceed () {
      this.$message.warning('最多只能上传3张图片')
    },
    beforeUpluad (file) {
      if ((file.size / 1024 / 1024) > 5) {
        this.$message.warning('上传图片大小不能超过5mb')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
  .el-select-dropdown__item{
    height: auto !important;
  }
</style>
