<template>
  <TableLayout :permissions="['renthouse:rhappointshot:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="预约状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择" @change="search">
          <el-option label="已预约" :value="0"></el-option>
          <el-option label="服务中" :value="1"></el-option>
          <el-option label="待支付" :value="2"></el-option>
          <el-option label="已完成" :value="3"></el-option>
          <el-option label="已取消" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="userNickName" label="用户名" min-width="100px"></el-table-column>
        <el-table-column prop="usePhone" label="手机号" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="预约状态" min-width="100px">
          <template slot-scope="{row}">
            <el-tag v-if="row.status == 0">已预约</el-tag>
            <el-tag v-if="row.status == 1">服务中</el-tag>
            <el-tag v-if="row.status == 2">待支付</el-tag>
            <el-tag v-if="row.status == 3">已完成</el-tag>
            <el-tag v-if="row.status == 4">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="serviceTime" label="服务时间" min-width="150px"></el-table-column>
        <el-table-column prop="address" label="拍摄地点" min-width="200px"></el-table-column>
        <el-table-column prop="latitude" label="精度" min-width="100px"></el-table-column>
        <el-table-column prop="longitude" label="纬度" min-width="100px"></el-table-column>
        <el-table-column label="价格" min-width="100px">
          <template slot-scope="{row}">
            <span>{{row.price}}元</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="150px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['renthouse:rhappointshot:update', 'renthouse:rhappointshot:delete'])"
          label="操作"
          min-width="150"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="edit(row)" icon="el-icon-edit" v-if="[0,1].includes(row.status)" v-permissions="['renthouse:rhappointshot:update']">扭转状态</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['renthouse:rhappointshot:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaRhAppointShotWindow ref="operaRhAppointShotWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaRhAppointShotWindow from '@/components/rentHouse/OperaRhAppointShotWindow'
  import { updateById } from '@/api/rentHouse/rhAppointShot'
  export default {
    name: 'RhAppointShot',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaRhAppointShotWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          status: ''
        }
      }
    },
    created () {
      this.config({
        module: '预约拍摄',
        api: '/rentHouse/rhAppointShot',
        'field.id': 'id',
        'field.main': 'userNickName',
        sorts: [{
          property: 'CREATE_TIME',
          direction: 'DESC'
        }]
      })
      this.search()
    },
    methods: {
      edit(row) {
        if (row.status === 1 && row.price === 0) {
          this.$confirm(`确定要扭转为已完成?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            updateById({
              id: row.id,
              status: 3
            }).then(res => {
              this.search()
            })
          }).catch(() => {

          });
        } else {
          this.$confirm(`确定要扭转为${row.status === 0 ? '服务中' : '待支付'}?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            updateById({
              id: row.id,
              status: row.status === 0 ? 1 : 2
            }).then(res => {
              this.search()
            })
          }).catch(() => {

          });
        }
      }
    }
  }
</script>
