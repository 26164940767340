<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="标签名称" prop="tagName">
        <el-input v-model="form.tagName" placeholder="请输入标签名称" v-trim/>
      </el-form-item>

      <el-form-item label="标签颜色" prop="tagColor">
        <el-color-picker v-model="form.tagColor" color-format="rgb" :show-alpha="true" :predefine="predefine"></el-color-picker>
      </el-form-item>
      <el-form-item label="所属字典板块" prop="tagForSector">
        <el-select v-model="form.tagForSector" placeholder="请输入所属字典板块" v-trim>
          <el-option
            v-for="(item,itemIndex) in tagList"
            :key="itemIndex"
            :label="item.label"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCommunityArticleTagWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  props: {
    tagList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        tagName: '',
        tagForSector: '',
        tagColor: ''
      },
      predefine: ['rgba(254,240,201,1)', 'rgba(250,221,213,1)', 'rgba(215,229,255,1)'],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/communityArticleTag',
      'field.id': 'id'
    })
  }
}
</script>
