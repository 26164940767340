<template>
  <TableLayout :permissions="['communityarticletag:communityarticletag:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="标签名称" prop="tagName">
        <el-input v-model="searchForm.tagName" placeholder="请输入标签名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="所属字典板块" prop="tagForSector">
        <el-select v-model="searchForm.tagForSector" placeholder="请输入所属字典板块" @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in tagList"
            :key="itemIndex"
            :label="item.label"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['communityarticletag:communityarticletag:create', 'communityarticletag:communityarticletag:delete']">
        <li><el-button type="primary" @click="$refs.operaCommunityArticleTagWindow.open('新建')" icon="el-icon-plus" v-permissions="['communityarticletag:communityarticletag:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['communityarticletag:communityarticletag:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="" min-width="100px"></el-table-column>
        <el-table-column prop="tagName" label="标签名称" min-width="100px"></el-table-column>
        <el-table-column prop="tagForSector" label="所属字典板块" min-width="100px">
          <template v-slot="{ row }">
            {{ tagList && tagList[tagListMatch.indexOf(row.tagForSector)] && tagList[tagListMatch.indexOf(row.tagForSector)].label }}
          </template>
        </el-table-column>
        <el-table-column prop="tagName" label="标签颜色" min-width="100px">
          <template v-slot="{ row }">
            <el-tag :type="row.tagColor" :color="row.tagColor"></el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="tagArticleNum" label="标签文章量" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['communityarticletag:communityarticletag:update', 'communityarticletag:communityarticletag:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCommunityArticleTagWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['communityarticletag:communityarticletag:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['communityarticletag:communityarticletag:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCommunityArticleTagWindow ref="operaCommunityArticleTagWindow" @success="handlePageChange" :tagList="tagList"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCommunityArticleTagWindow from '@/components/rentHouse/OperaCommunityArticleTagWindow'
import { findByDictCode } from '../../api/system/dictData'
export default {
  name: 'CommunityArticleTag',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCommunityArticleTagWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        tagName: '',
        tagForSector: ''
      },
      tagList: []
    }
  },
  async created () {
    this.config({
      module: '',
      api: '/rentHouse/communityArticleTag',
      'field.id': 'id',
      'field.main': 'id'
    })
    await this.getDictCode()
    this.search()
  },
  computed: {
    tagListMatch () {
      return this.tagList.map(item => {
        return item.code
      })
    }
  },
  methods: {
    async getDictCode () {
      const tagList = await findByDictCode({
        dictCode: 'community_tag'
      })
      this.tagList = tagList
    }
  }
}
</script>
