<template>
    <GlobalWindow
        :title="title"
        :visible.sync="visible"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="会员类型" prop="memberType">
                <el-select v-model="form.memberType" placeholder="请选择会员类型">
                    <el-option label="增值服务" :value="0"></el-option>
                    <el-option label="代答服务" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="会员的名称" prop="memberName">
                <el-input v-model="form.memberName" placeholder="请输入会员的名称" v-trim/>
            </el-form-item>
            <el-form-item label="首次月卡价格" prop="firstMonthPrice">
                <el-input v-model="form.firstMonthPrice" placeholder="请输入首次月卡价格" v-trim/>
            </el-form-item>
            <el-form-item label="首次季卡价格" prop="firstQuarterPrice">
                <el-input v-model="form.firstQuarterPrice" placeholder="请输入首次季卡价格" v-trim/>
            </el-form-item>
            <el-form-item label="首次年卡价格" prop="firstYearPrice">
                <el-input v-model="form.firstYearPrice" placeholder="请输入首次年卡价格" v-trim/>
            </el-form-item>
            <el-form-item label="月卡价格" prop="monthPrice">
                <el-input v-model="form.monthPrice" placeholder="请输入月卡价格" v-trim/>
            </el-form-item>
            <el-form-item label="季卡价格" prop="quarterPrice">
                <el-input v-model="form.quarterPrice" placeholder="请输入季卡价格" v-trim/>
            </el-form-item>
            <el-form-item label="年卡价格" prop="yearPrice">
                <el-input v-model="form.yearPrice" placeholder="请输入年卡价格" v-trim/>
            </el-form-item>
            <el-form-item label="首次会员价格" prop="firstMemberPrice">
                <el-input v-model="form.firstMemberPrice" placeholder="请输入首次会员价格" v-trim/>
            </el-form-item>
            <el-form-item label="会员价格" prop="memberPrice">
                <el-input v-model="form.memberPrice" placeholder="请输入会员价格" v-trim/>
            </el-form-item>
            <el-form-item label="首次非会员价格" prop="firstNoMemberPrice">
                <el-input v-model="form.firstNoMemberPrice" placeholder="请输入首次非会员价格" v-trim/>
            </el-form-item>
            <el-form-item label="非会员价格" prop="noMemberPrice">
                <el-input v-model="form.noMemberPrice" placeholder="请输入非会员价格" v-trim/>
            </el-form-item>
        </el-form>
    </GlobalWindow>
</template>

<script>
    import BaseOpera from '@/components/base/BaseOpera'
    import GlobalWindow from '@/components/common/GlobalWindow'
    export default {
        name: 'OperaRhServiceMemberWindow',
        extends: BaseOpera,
        components: { GlobalWindow },
        data () {
            return {
                // 表单数据
                form: {
                    id: null,
                    memberType: '',
                    memberName: '',
                    firstMonthPrice: '',
                    firstQuarterPrice: '',
                    firstYearPrice: '',
                    monthPrice: '',
                    quarterPrice: '',
                    yearPrice: '',
                    firstMemberPrice: '',
                    memberPrice: '',
                    firstNoMemberPrice: '',
                    noMemberPrice: '',
                    createTime: '',
                    updateTime: ''
                },
                // 验证规则
                rules: {
                }
            }
        },
        created () {
            this.config({
                api: '/rentHouse/rhServiceMember',
                'field.id': 'id'
            })
        }
    }
</script>
