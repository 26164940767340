<template>
  <TableLayout :permissions="['communityreview:communityreview:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="相关用户" prop="forUserId">
        <el-select
          v-model="searchForm.forUserId"
          placeholder="请选择相关用户"
          filterable
          remote
          reserve-keyword
          :remote-method="getUserList"
          :loading="tagLoading"
         @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in forUserList"
            :key="itemIndex"
            :label="item.mobile"
            :value="item.id">
            <div style="display: flex;align-items: center;">
              <el-avatar shape="circle" style="width:50px;height: 50px;" fit="cover" :src="imgUrl + item.avatar"></el-avatar>
              <div style="padding-left: 8px;">
                <span><b>{{ item.username }}</b></span>
                <div>手机: {{ item.mobile }}</div>
              </div>

            </div>
          </el-option>
         </el-select>
      </el-form-item>
      <el-form-item label="所属文章ID" prop="forArticleId">
        <el-input v-model="searchForm.forArticleId" placeholder="请输入所属文章ID" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="审核状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择审核状态"  @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in statusList"
            :key="itemIndex"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['communityreview:communityreview:create', 'communityreview:communityreview:delete']">
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['communityreview:communityreview:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="" min-width="100px"></el-table-column>
        <el-table-column prop="reviewContent" label="评论内容" min-width="100px"></el-table-column>
        <el-table-column prop="forReviewId" label="发送对象" min-width="100px">
          <template v-slot="{ row }">
            <span v-if="row.toNickName || row.toPageName">@{{ row.toPageName ||  row.toNickName}}</span>
            <span v-else-if="row.forReview">回复评论: {{ row.forReview }}</span>
            <span v-else>文章评论: {{ row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="forUserId" label="所属用户" min-width="100px">
          <template v-slot="{ row }">
            <span>{{ row.forPageName || row.forNickName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="所属文章" min-width="100px">
          <template v-slot="{ row }">
            <span>《{{ row.title || '文章已被删除' }}》</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['communityreview:communityreview:update', 'communityreview:communityreview:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="setReviewStatus(row)" v-permissions="['communityreview:communityreview:update']">设置状态</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['communityreview:communityreview:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCommunityReviewWindow ref="operaCommunityReviewWindow" @success="handlePageChange"/>
    <el-dialog title="设置状态" :visible.sync="setStatusVisible" width="30%" @close="setStatusVisible = false">
      <el-form>
        <el-form-item label="审核状态" prop="status">
          <el-select v-model="currentItem.status" placeholder="请选择审核状态"  @keypress.enter.native="search">
            <el-option
              v-for="(item,itemIndex) in statusList"
              :key="itemIndex"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>

          <el-button type="primary" @click="setReviewStatusPost" style="margin-left:10px;">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCommunityReviewWindow from '@/components/rentHouse/OperaCommunityReviewWindow'
import { fetchList } from '../../api/system/user'
import { updateById } from '../../api/rentHouse/communityReview'
export default {
  name: 'CommunityReview',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCommunityReviewWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        forUserId: '',
        forArticleId: '',
        status: ''
      },
      currentItem: {},
      setStatusVisible: false,
      imgUrl: process.env.VUE_APP_API_IMG,
      tagLoading: false,
      forUserList: [],
      statusList: [
        { label: '待审核', value: '1' },
        { label: '已审核', value: '2' },
        { label: '已驳回', value: '3' }
      ]
    }
  },
  created () {
    this.config({
      module: '',
      api: '/rentHouse/communityReview',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()

    this.$nextTick(() => {
      const { articleId } = this.$route.query
      this.searchForm.forArticleId = articleId
    })
    this.getUserList()
  },
  methods: {
    async getUserList (keyword) {
      const res = await fetchList({
        page: 1,
        capacity: 50,
        model: {
          username: '',
          nickName: '',
          realname: '',
          mobile: keyword
        },
        sorts: [{ property: 'CREATE_TIME', direction: 'DESC' }]
      })

      this.forUserList = res.records
    },
    setReviewStatus (row) {
      this.currentItem = { ...row }
      this.setStatusVisible = true
    },
    async setReviewStatusPost () {
      await updateById({
        ...this.currentItem
      })
      this.$tip.apiSuccess('修改成功')
      this.$emit('success')
      this.handlePageChange()
      this.setStatusVisible = false
    }
  }
}
</script>
<style scoped>
  .el-select-dropdown__item{
    height: auto !important;
  }
</style>
