<template>
  <TableLayout :permissions="['renthouse:rhhouse:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="房源标题" prop="keyword">
        <el-input v-model="searchForm.keyword" placeholder="请输入房源标题" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input type="number" v-model="searchForm.phone" placeholder="请输入手机号码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['renthouse:rhhouse:create', 'renthouse:rhhouse:delete']">
        <li><el-button type="primary" @click="$refs.OperaSpecialOfferWindow.open('新建特惠房源')" icon="el-icon-plus" v-permissions="['renthouse:rhhouse:create']">新建</el-button></li>
        <li><el-button type="primary" @click="importHouse">导入房源</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhhouse:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <el-table-column prop="cityName" label="城市" min-width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="房源标题" min-width="200px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="小区位置" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="specialOffer" label="特惠售价" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="marketPrice" label="市场价" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="evaluationPrice" label="评估价" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="maxLoanAmount" label="最高贷款额度" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="preLoanInterestRate" label="特患贷款利率" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="monthlyRepayment" label="月供还款" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createUserName" label="发布人" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="发布时间" min-width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column
          label="操作"
          min-width="250"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" slot="reference" @click="$refs.OperaSpecialOfferWindow.open('编辑特惠房源', row)">编辑</el-button>
            <el-button type="text" @click="deleteById(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaSpecialOfferWindow ref="OperaSpecialOfferWindow" @success="handlePageChange"/>
    <!--  导入房源  -->
    <input type="file" @change="uploads" ref="getFile" accept=".xls" style="opacity: 0;" />
  </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaSpecialOfferWindow from '@/components/rentHouse/OperaSpecialOfferWindow'
  import { updateById, importHouse } from '@/api/rentHouse/rhHouse'
  export default {
    name: 'specialOffer',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaSpecialOfferWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          rentType: '',
          keyword: '',
          phone: '',
          houseCategory: 2
        }
      }
    },
    created () {
      this.config({
        module: '房源信息表',
        api: '/rentHouse/rhHouse',
        'field.id': 'id',
        'field.main': 'title'
      })
      this.search()
    },
    methods: {
      uploads(e) {
        const formdate = new FormData()
        formdate.append('file', e.target.files[0])
        importHouse(formdate)
          .then(res => {
            this.$refs.getFile.value = ''
            this.search()
          })
      },
      importHouse () {
        this.$refs.getFile.click()
      },
      offShelf (id, status) {
        this.$confirm(`${status === 1 ? '确定下架此房源吗?' : '确定上架此房源吗?'}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          updateById({
            id,
            status: status === 1 ? 0 : '1'
          }).then(res => {
            this.$message.success('操作成功')
            this.search()
          })
        })
      },
      examine (type, id) {
        var reviewStatus = ''
        if (type === 1) {
          reviewStatus = 1
        } else {
          reviewStatus = 2
        }
        updateById({
          id,
          reviewStatus
        }).then(res => {
          this.$message.success('操作成功')
          this.search()
        })
      }
    }
  }
</script>
