<template>
    <GlobalWindow
        :title="title"
        :visible.sync="visible"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="城市名称" prop="cityName">
                <el-input v-model="form.cityName" placeholder="请输入城市名称" v-trim/>
            </el-form-item>
            <el-form-item label="城市英文名称" prop="cityEn">
                <el-input v-model="form.cityEn" placeholder="请输入城市英文名称" v-trim/>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input type="number" v-model="form.sort" placeholder="请输入排序" v-trim/>
            </el-form-item>
            <el-form-item label="城市图片" prop="cityPic">
                <UploadAvatarImage :file="file" @uploadSuccess="uploadSuccess" />
            </el-form-item>
        </el-form>
    </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import UploadAvatarImage from '@/components/common/UploadAvatarImage'
export default {
  name: 'OperaRhCityInfoWindow',
  extends: BaseOpera,
  components: { GlobalWindow, UploadAvatarImage },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        cityName: '',
        cityEn: '',
        cityCode: '',
        cityPic: '',
        sort: ''
      },
      file: {
        imgurl: '',
        imgurlfull: ''
      },
      // 验证规则
      rules: {
        cityName: [
          { required: true, message: '请输入城市名称', trigger: 'blur' }
        ],
        cityEn: [
          { required: true, message: '请输入城市英文名称', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/rhCityInfo',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.file.imgurlfull = ''
      this.file.imgurl = ''
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.file.imgurlfull = process.env.VUE_APP_API_IMG + this.form.cityPic
        this.file.imgurl = this.form.cityPic
      })
    },
    uploadSuccess (res) {
      this.form.cityPic = res.imgurl
      this.file.imgurlfull = res.imgurlfull
    }
  }
}
</script>
