<template>
  <TableLayout :permissions="['renthouse:rhcomment:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['renthouse:rhcomment:delete']">
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhcomment:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="userName" label="发言用户" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="评论的内容" min-width="100px"></el-table-column>
        <el-table-column prop="number" label="点赞数" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['renthouse:rhcomment:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['renthouse:rhcomment:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
  </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  export default {
    name: 'rhComment',
    extends: BaseTable,
    components: { TableLayout, Pagination },
    data () {
      return {
        // 搜索
        searchForm: {
        }
      }
    },
    created () {
      this.config({
        module: '评论表',
        api: '/rentHouse/rhComment',
        'field.id': 'id',
        'field.main': 'id',
        sorts: [{
          property: 'CREATE_TIME',
          direction: 'DESC'
        }]
      })
      this.search()
    }
  }
</script>
