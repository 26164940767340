<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="评论内容" prop="reviewContent">
        <el-input v-model="form.reviewContent" placeholder="请输入评论内容" v-trim/>
      </el-form-item>
      <el-form-item label="所属回复" prop="forReviewId">
        <el-input v-model="form.forReviewId" placeholder="请输入所属回复" v-trim/>
      </el-form-item>
      <el-form-item label="所属用户" prop="forUserId">
        <el-input v-model="form.forUserId" placeholder="请输入所属用户" v-trim/>
      </el-form-item>
      <el-form-item label="所属文章ID" prop="forArticleId">
        <el-input v-model="form.forArticleId" placeholder="请输入所属文章ID" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCommunityReviewWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        reviewContent: '',
        forReviewId: '',
        forUserId: '',
        forArticleId: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/communityReview',
      'field.id': 'id'
    })
  }
}
</script>
