<template>
    <TableLayout :permissions="['renthouse:rhhouse:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
          <el-form-item label="城市" prop="cityId">
              <el-select v-model="searchForm.cityId" placeholder="请选择城市" @change="search">
                <el-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :label="item.cityName"
                  :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="房源标题" prop="keyword">
                <el-input v-model="searchForm.keyword" placeholder="请输入房源标题" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <el-form-item label="出租类型" prop="rentType">
                <el-select v-model="searchForm.rentType" placeholder="请选择" @change="search">
                    <el-option label="业主" :value="0"></el-option>
                    <el-option label="室友" :value="1"></el-option>
                    <el-option label="转租" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否出售" prop="enableSell">
                <el-select v-model="searchForm.enableSell" placeholder="请选择" @change="search">
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布人手机号码" prop="publisherPhone">
                <el-input type="number" v-model="searchForm.publisherPhone" placeholder="请输入发布人手机号码" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <el-form-item label="房东手机号码" prop="phone">
                <el-input type="number" v-model="searchForm.phone" placeholder="请输入房东手机号码" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <el-form-item label="房源状态" prop="status">
                <el-select v-model="searchForm.status" placeholder="请选择" @change="search">
                    <el-option label="下架" :value="0"></el-option>
                    <el-option label="上架" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="审核状态" prop="reviewStatus">
                <el-select v-model="searchForm.reviewStatus" placeholder="请选择" @change="search">
                    <el-option label="审核中" :value="0"></el-option>
                    <el-option label="已通过" :value="1"></el-option>
                    <el-option label="未通过" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否推荐" prop="isRecommend">
                <el-select v-model="searchForm.isRecommend" placeholder="请选择" @change="search">
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否本周推荐" prop="isRecommendWeek">
                <el-select v-model="searchForm.isRecommendWeek" placeholder="请选择" @change="search">
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="是否会员" prop="isMember">
            <el-select v-model="searchForm.isMember" placeholder="请选择" @change="search">
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房源类别" prop="isMiddlemanLandlord">
            <el-select v-model="searchForm.isMiddlemanLandlord" placeholder="请选择" @change="search">
              <el-option label="房东" :value="0"></el-option>
              <el-option label="二房东" :value="1"></el-option>
            </el-select>
          </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <ul class="toolbar" v-permissions="['renthouse:rhhouse:create', 'renthouse:rhhouse:delete']">
                <li><el-button type="primary" @click="importHouse">导入房源</el-button></li>
                <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhhouse:delete']">删除</el-button></li>
            </ul>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                border
                default-expand-all
                :row-class-name="getRowClassName"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="expand">
                  <template slot-scope="{row}">
                    <el-button type="text" slot="reference" @click="$refs.operaRhHouseWindow.open('审核房源', row)" v-if="row.reviewStatus === 0">审核</el-button>
                    <el-button type="text" @click="$refs.chatRoom.open('聊天室列表', { houseId: row.id })">查看聊天室</el-button>
                    <el-button type="text" @click="$refs.PublishListings.open('编辑房源', { ...row, label: row.label.split(',') }, 2)">编辑</el-button>
                    <el-button type="text" @click="offShelf(row.id, row.status)" v-if="row.reviewStatus === 1">{{row.status === 0 ? '上架房源' : '下架房源'}}</el-button>
                    <el-button type="text" @click="setCategry(row.id)">{{row.isMiddlemanLandlord === 0 ? '设为二房东' : '设为房东'}}</el-button>
                    <el-button type="text" v-if="row.reviewStatus === 1 && row.status === 1" @click="setRecommend(row)">{{row.isRecommend === 0 ? '设为推荐' : '取消推荐'}}</el-button>
                    <el-button type="text" v-if="row.reviewStatus === 1 && row.status === 1" @click="setRecommendWeek(row)">{{row.isRecommendWeek != 1 ? '设为本周推荐' : '取消本周推荐'}}</el-button>
                    <el-button type="text" @click="submitInfo(row.id)">备注信息</el-button>
                    <el-button type="text" @click="$refs.operaRhHouseWindow.open('房源详情', {...row, seeType: 'desc'})">详情</el-button>
                    <el-button type="text" @click="deleteById(row)">删除</el-button>
                  </template>
                </el-table-column>
                <el-table-column type="selection" width="55" fixed="left"></el-table-column>
                <el-table-column prop="title" label="小区名称" min-width="200px"></el-table-column>
                <el-table-column label="栋数">
                    <template slot-scope="{row}">
                        <span>{{row.buildingNum}}栋</span>
                    </template>
                </el-table-column>
                <el-table-column prop="roomNum" label="门牌号"></el-table-column>
                <el-table-column label="户型" min-width="100px">
                    <template slot-scope="{row}">
                        <span>{{row.bedroomNum}}房{{row.saloonNum || ''}}厅{{row.toiletNum || ''}}卫</span>
                    </template>
                </el-table-column>
                <el-table-column label="面积">
                    <template slot-scope="{row}">
                        <span>{{row.area}}㎡</span>
                    </template>
                </el-table-column>
                <el-table-column label="房源状态" min-width="100px">
                    <template slot-scope="{row}">
                        <span style="color: red;" v-if="row.status === 0">下架</span>
                        <span style="color: green;" v-if="row.status === 1">上架</span>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="租金（元）"></el-table-column>
                <el-table-column prop="sellPrice" label="售价（万元）"></el-table-column>
                <el-table-column label="审核状态" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.reviewStatus === 0">审核中</span>
                        <span style="color: green;" v-if="row.reviewStatus === 1">已通过</span>
                        <span style="color: red;" v-if="row.reviewStatus === 2">未通过</span>
                    </template>
                </el-table-column>
                <el-table-column prop="reviewUserName" label="审核人" min-width="100px"></el-table-column>
                <el-table-column prop="createTime" label="发布时间" min-width="100px"></el-table-column>
                <el-table-column prop="createUserName" label="发布人" min-width="100px"></el-table-column>
                <el-table-column prop="publisherPhone" label="发布人手机号" min-width="100px"></el-table-column>
                <el-table-column prop="phone" label="房东手机号" min-width="100px"></el-table-column>
                <el-table-column label="房源类别" min-width="90px">
                  <template slot-scope="{row}">
                      <span v-if="row.isMiddlemanLandlord === 0">房东</span>
                      <span v-else-if="row.isMiddlemanLandlord === 1">二房东</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
                <!-- <el-table-column
                  label="操作"
                  min-width="500"
                  fixed="right"
                >
                  <template slot-scope="{row}">
                    <el-button type="text" slot="reference" @click="$refs.operaRhHouseWindow.open('审核房源', row)" v-if="row.reviewStatus === 0">审核</el-button>
                    <el-button type="text" @click="$refs.chatRoom.open('聊天室列表', { houseId: row.id })">查看聊天室</el-button>
                    <el-button type="text" @click="$refs.PublishListings.open('编辑房源', { ...row, label: row.label.split(',') }, 2)">编辑</el-button>
                    <el-button type="text" @click="offShelf(row.id, row.status)" v-if="row.reviewStatus === 1">{{row.status === 0 ? '上架房源' : '下架房源'}}</el-button>
                    <el-button type="text" @click="setCategry(row.id)">{{row.isMiddlemanLandlord === 0 ? '设为二房东' : '设为房东'}}</el-button>
                    <el-button type="text" v-if="row.reviewStatus === 1 && row.status === 1" @click="setRecommend(row)">{{row.isRecommend === 0 ? '设为推荐' : '取消推荐'}}</el-button>
                    <el-button type="text" v-if="row.reviewStatus === 1 && row.status === 1" @click="setRecommendWeek(row)">{{row.isRecommendWeek != 1 ? '设为本周推荐' : '取消本周推荐'}}</el-button>
                    <el-button type="text" @click="submitInfo(row.id)">备注信息</el-button>
                    <el-button type="text" @click="$refs.operaRhHouseWindow.open('房源详情', {...row, seeType: 'desc'})">详情</el-button>
                    <el-button type="text" @click="deleteById(row)">删除</el-button>
                  </template>
              </el-table-column> -->
            </el-table>
            <pagination
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
              :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <!-- 新建/修改 -->
        <OperaRhHouseWindow ref="operaRhHouseWindow" @success="handlePageChange"/>
        <!--    新建/修改    -->
        <PublishListings ref="PublishListings" @success="handlePageChange"/>
        <!-- 聊天室 -->
        <ChatRoom ref="chatRoom" />
        <input type="file" @change="uploads" ref="getFile" accept=".xls" style="opacity: 0;" />
    </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import PublishListings from '@/components/rentHouse/publishListings'
import OperaRhHouseWindow from '@/components/rentHouse/OperaRhHouseWindow'
import ChatRoom from '@/components/rentHouse/chatRoom'
import { updateById, importHouse, setMiddlemanLandlord } from '@/api/rentHouse/rhHouse'
import { fetchList } from '@/api/rentHouse/rhCityInfo'
export default {
  name: 'RhHouse',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaRhHouseWindow, PublishListings, ChatRoom },
  data () {
    return {
      // 搜索
      searchForm: {
        rentType: '',
        keyword: '',
        enableSell: '',
        publisherPhone: '',
        phone: '',
        status: '',
        reviewStatus: '',
        isRecommend: '',
        isMember: '',
        isMiddlemanLandlord: ''
      },
      cityList: []
    }
  },
  created () {
    this.config({
      module: '房源信息表',
      api: '/rentHouse/rhHouse',
      'field.id': 'id',
      'field.main': 'id',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
    this.getCity()
  },
  methods: {
    submitInfo (id) {
      this.$prompt('请输入备注信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        inputType: 'textarea'
      }).then(({ value }) => {
        updateById({
          id: id,
          remark: value
        }).then(res => {
          this.$message.success('操作成功')
          this.search()
        })
      }).catch(() => {

      })
    },
    getCity () {
      fetchList({
        capacity: 100,
        model: {
          display: 1
        },
        page: 1
      }).then(res => {
        this.cityList = res.records
      })
    },
    getRowClassName ({ row, rowIndex }) {
      // 例如，根据某字段的值改变背景色
      if (row.isClaim === 0) {
        return 'active-row'
      } else {
        return 'inactive-row'
      }
    },
    setCategry (id) {
      setMiddlemanLandlord(id)
        .then(res => {
          this.search()
        })
    },
    setRecommendWeek (row) {
      this.$confirm(`${row.isRecommend === 0 ? '确定设为本周推荐房源吗?' : '确定取消本周推荐房源吗?'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateById({
          id: row.id,
          isRecommendWeek: row.isRecommendWeek !== 1 ? 1 : 0
        }).then(res => {
          this.$message.success('操作成功')
          this.search()
        })
      })
    },
    setRecommend (row) {
      this.$confirm(`${row.isRecommend === 0 ? '确定设为推荐房源吗?' : '确定取消推荐房源吗?'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateById({
          id: row.id,
          isRecommend: row.isRecommend === 0 ? 1 : 0
        }).then(res => {
          this.$message.success('操作成功')
          this.search()
        })
      })
    },
    uploads (e) {
      const formdate = new FormData()
      formdate.append('file', e.target.files[0])
      importHouse(formdate)
        .then(res => {
          this.$refs.getFile.value = ''
          this.search()
        })
    },
    importHouse () {
      this.$refs.getFile.click()
    },
    offShelf (id, status) {
      this.$confirm(`${status === 1 ? '确定下架此房源吗?' : '确定上架此房源吗?'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateById({
          id,
          status: status === 1 ? 0 : '1'
        }).then(res => {
          this.$message.success('操作成功')
          this.search()
        })
      })
    },
    examine (type, id) {
      var reviewStatus = ''
      if (type === 1) {
        reviewStatus = 1
      } else {
        reviewStatus = 2
      }
      updateById({
        id,
        reviewStatus
      }).then(res => {
        this.$message.success('操作成功')
        this.search()
      })
    }
  }
}
</script>

<style>
.active-row {
  background-color: #ffdcaf !important; /* 浅绿色背景 */
}
.inactive-row {
  background-color: #ffffff !important; /* 浅红色背景 */
}
</style>
