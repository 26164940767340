<template>
    <GlobalWindow
        :title="title"
        :visible.sync="visible"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="会员名称" prop="memberName">
                <el-input v-model="form.memberName" placeholder="请输入会员名称" v-trim/>
            </el-form-item>
            <el-form-item label="发起联系数量" prop="requestNum">
                <el-input-number style="width: 150px;" v-model="form.requestNum" :min="0" :max="100" label="联系数量" v-trim></el-input-number>
            </el-form-item>
            <el-form-item label="刷新房源间隔h/次" prop="refreshInterval">
                <el-input-number style="width: 150px;" v-model="form.refreshInterval" :min="0" :max="100" label="刷新房源间隔h/次" v-trim></el-input-number>
            </el-form-item>
            <el-form-item label="服务信息" prop="serviceInfo">
                <el-radio-group v-model="form.serviceInfo">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="查看谁收藏我的房源" prop="getFollowHouse">
                <el-radio-group v-model="form.getFollowHouse">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="查看浏览信息" prop="getViewHouse">
                <el-radio-group v-model="form.getViewHouse">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="电子签约" prop="esign">
                <el-radio-group v-model="form.esign">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="管理服务房源" prop="manageHouse">
                <el-radio-group v-model="form.manageHouse">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="上架数量（租房）" prop="rentNum">
                <el-input-number style="width: 150px;" v-model="form.rentNum" :min="0" :max="100" label="上架数量" v-trim></el-input-number>
            </el-form-item>
            <el-form-item label="售卖数量（售房）" prop="salesNum">
                <el-input-number style="width: 150px;" v-model="form.salesNum" :min="0" :max="100" label="售卖数量" v-trim></el-input-number>
            </el-form-item>
            <el-form-item label="会员价格" prop="price">
                <el-tabs v-model="priceType" type="border-card">
                    <el-tab-pane label="月卡" name="0">
                        <el-input type="number" style="width: 150px;" v-model="form.monthPrice" placeholder="请输入价格"></el-input> <span>元</span>
                    </el-tab-pane>
                    <el-tab-pane label="月卡(首次)" name="1">
                        <el-input type="number" style="width: 150px;" v-model="form.firstMonthPrice" placeholder="请输入价格"></el-input> <span>元</span>
                    </el-tab-pane>
                    <el-tab-pane label="季卡" name="2">
                        <el-input type="number" style="width: 150px;" v-model="form.quarterPrice" placeholder="请输入价格"></el-input> <span>元</span>
                    </el-tab-pane>
                    <el-tab-pane label="季卡(首次)" name="3">
                        <el-input type="number" style="width: 150px;" v-model="form.firstQuarterPrice" placeholder="请输入价格"></el-input> <span>元</span>
                    </el-tab-pane>
                    <el-tab-pane label="年卡" name="4">
                        <el-input type="number" style="width: 150px;" v-model="form.yearPrice" placeholder="请输入价格"></el-input> <span>元</span>
                    </el-tab-pane>
                    <el-tab-pane label="年卡(首次)" name="5">
                        <el-input type="number" style="width: 150px;" v-model="form.firstYearPrice" placeholder="请输入价格"></el-input> <span>元</span>
                    </el-tab-pane>
                </el-tabs>
            </el-form-item>
        </el-form>
    </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaRhMemberWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      priceType: '0',
      // 表单数据
      form: {
        id: null,
        memberName: '',
        requestNum: 0,
        refreshInterval: 1,
        getFollowHouse: 0,
        getViewHouse: 0,
        esign: 0,
        manageHouse: 0,
        rentNum: 0,
        salesNum: 0,
        serviceInfo: 0,
        monthPrice: '',
        yearPrice: '',
        quarterPrice: '',
        firstYearPrice: '',
        firstQuarterPrice: '',
        firstMonthPrice: ''
      },
      // 验证规则
      rules: {
        memberName: [
          { required: true, message: '会员名称不能为空', trigger: 'blur' }
        ],
        requestNum: [
          { required: true, message: '发起联系数量不能为空', trigger: 'blur' }
        ],
        serviceInfo: [
          { required: true, message: '服务信息不能为空', trigger: 'blur' }
        ],
        refreshInterval: [
          { required: true, message: '刷新房源间隔h/次不能为空', trigger: 'blur' }
        ],
        getFollowHouse: [
          { required: true, message: '查看谁收藏我的房源不能为空', trigger: 'blur' }
        ],
        getViewHouse: [
          { required: true, message: '查看浏览信息不能为空', trigger: 'blur' }
        ],
        esign: [
          { required: true, message: '电子签约不能为空', trigger: 'blur' }
        ],
        manageHouse: [
          { required: true, message: '房源管理服务房源不能为空', trigger: 'blur' }
        ],
        rentNum: [
          { required: true, message: '上架数量不能为空', trigger: 'blur' }
        ],
        salesNum: [
          { required: true, message: '售卖数量不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/rhMember',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.priceType = '0'
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    }
  }
}
</script>
