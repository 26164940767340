<template>
  <GlobalWindow
    :title="title"
    width="900px"
    :withFooter="false"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="box_list">
      <div class="box_list_search">
        <div class="box_list_search_item">
          <el-input v-model="searchForm.phone" placeholder="请输入手机号"></el-input>
        </div>
        <div class="box_list_search_item">
          <el-input v-model="searchForm.userName" placeholder="请输入昵称"></el-input>
        </div>
        <div class="box_list_search_item">
          <el-select v-model="searchForm.type" @change="getList" placeholder="请选择积分类型">
            <el-option label="邀请注册" :value="1"></el-option>
            <el-option label="扫码积分" :value="2"></el-option>
            <el-option label="身份证扫码" :value="3"></el-option>
            <el-option label="聊天积分" :value="4"></el-option>
            <el-option label="发布求租" :value="5"></el-option>
            <el-option label="查看房源详情" :value="6"></el-option>
            <el-option label="收藏房源" :value="8"></el-option>
            <el-option label="（房东）看房预约" :value="9"></el-option>
            <el-option label="（房客）看房预约" :value="10"></el-option>
            <el-option label="电子签约" :value="11"></el-option>
            <el-option label="点赞积分" :value="12"></el-option>
            <el-option label="微信分享" :value="13"></el-option>
          </el-select>
        </div>
        <div class="box_list_search_item">
          <el-select v-model="searchForm.delFlag" @change="getList" placeholder="请选择结算状态">
            <el-option label="未结算积分及返佣" :value="0"></el-option>
            <el-option label="已结算返佣" :value="1"></el-option>
            <el-option label="已结算积分" :value="2"></el-option>
            <el-option label="已结算的积分及分佣" :value="3"></el-option>
          </el-select>
        </div>
        <div class="box_list_search_item">
          <el-select v-model="searchForm.date" @change="changeDate" placeholder="请选择日期范围">
            <el-option label="不限日期" :value="null"></el-option>
            <el-option label="近一天" :value="1"></el-option>
            <el-option label="近三天" :value="3"></el-option>
            <el-option label="近七天" :value="7"></el-option>
            <el-option label="近一月" :value="30"></el-option>
            <el-option label="近一季度" :value="90"></el-option>
            <el-option label="近一年" :value="360"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="getList">搜索</el-button>
      </div>
      <div class="box_list_item" v-for="(item, index) in list" :key="index">
        <div class="box_list_item_left">
          <div class="box_list_item_left_img">
            <img :src="item.avatar" />
          </div>
          <div class="box_list_item_left_info">
            <span v-if="item.address">{{item.userName}} - <el-tag>{{ item.address }}</el-tag></span>
            <span v-else>{{item.userName}}</span>
            <span>手机号：{{item.mobile}}</span>
            <span>{{item.createTime}}</span>
          </div>
        </div>
        <div class="box_list_item_f">返佣{{item.amount || 0}}元</div>
        <div class="box_list_item_right">
          <span>+{{item.integral || 0}}</span>
          <span>{{returnInfo(item.type)}}</span>
        </div>
        <el-button type="danger" icon="el-icon-delete" circle style="margin-left: 15px;" @click="deleItem(item.id, item.codeId)"></el-button>
      </div>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { invitationListOneself, dataDelete } from '@/api/rentHouse/rhUserCode'
export default {
  name: 'OperaRhUserCode',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        addUserId: null
      },
      searchForm: {
        phone: '',
        date: '',
        start: '',
        end: '',
        userName: '',
        delFlag: 0,
        type: ''
      },
      list: []
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.searchForm.start = ''
      this.searchForm.end = ''
      this.searchForm.phone = ''
      this.searchForm.userName = ''
      this.searchForm.delFlag = 0
      this.list = []
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getList()
      })
    },
    deleItem (id, codeId) {
      this.$confirm('确认删除该记录吗？删除后将无法恢复！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataDelete({ id, codeId })
          .then(res => {
            this.getList()
          })
      }).catch(() => {

      })
    },
    returnInfo (code) {
      switch (code) {
      case '1':
        return '邀请注册'
      case '2':
        return '扫码积分'
      case '3':
        return '身份证扫码'
      case '4':
        return '聊天积分'
      case '5':
        return '发布求租'
      case '6':
        return '查看房源详情'
      case '7':
        return '查看求租详情'
      case '8':
        return '收藏房源'
      case '9':
        return '预约看房'
      case '10':
        return '预约看房'
      case '11':
        return '电子签约'
      case '12':
        return '收藏'
      case '13':
        console.log('分享房源')
        return '分享房源'
      case '14':
        console.log('完善资料')
        return '完善资料'
      }
    },
    getList () {
      this.list = []
      invitationListOneself({
        userId: this.form.addUserId,
        phone: this.searchForm.phone,
        startTime: this.searchForm.start,
        endTime: this.searchForm.end,
        userName: this.searchForm.userName,
        delFlag: this.searchForm.delFlag,
        types: ['1', '2'],
        type: this.searchForm.type
      }).then(res => {
        res.forEach(item => {
          item.avatar = process.env.VUE_APP_API_IMG + item.avatar
        })
        this.list = res
      })
    },
    changeDate (e) {
      if (!e) {
        this.searchForm.start = ''
        this.searchForm.end = ''
      } else {
        this.searchForm.start = this.getNDaysAgoWithTime(e)
        this.searchForm.end = this.getNDaysAgoWithTime(0)
      }
      this.getList()
    },
    getNDaysAgoWithTime (n) {
      // 获取当前日期和时间
      const currentDate = new Date()

      // 计算n天前的日期
      currentDate.setDate(currentDate.getDate() - n)

      // 格式化日期为 YYYY-MM-DD HH:mm:ss
      const year = currentDate.getFullYear()
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      const day = currentDate.getDate().toString().padStart(2, '0')
      const hours = currentDate.getHours().toString().padStart(2, '0')
      const minutes = currentDate.getMinutes().toString().padStart(2, '0')
      const seconds = currentDate.getSeconds().toString().padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .box_list {
    width: 100%;
    .box_list_search {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: sticky;
      top: 0;
      left: 0;
      background: #ffffff;
      .box_list_search_item {
        flex: 1;
        margin-right: 15px;
      }
    }
    .box_list_item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;
      &:last-child {
        margin: 0 !important;
      }
      .box_list_item_left {
        flex: 1;
        height: 52px;
        display: flex;
        align-items: center;
        .box_list_item_left_img {
          flex-shrink: 0;
          width: 52px;
          height: 52px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          img {
            width: 100%;
          }
        }
        .box_list_item_left_info {
          display: flex;
          flex-direction: column;
          span {
            &:nth-child(1) {
              font-weight: 400;
              font-size: 14px;
              color: #000000;
            }
            &:nth-child(2) {
              font-weight: 400;
              font-size: 12px;
              color: #434343;
              margin-top: 8px;
            }
            &:nth-child(3) {
              font-weight: 400;
              font-size: 12px;
              color: #434343;
              margin-top: 8px;
            }
          }
        }
      }
      .box_list_item_f {
        padding: 0 10px;
        box-sizing: border-box;
        height: 49px;
        line-height: 49px;
        text-align: center;
        background: #F5121D;
        border-radius: 14px;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        margin-right: 15px;
      }
      .box_list_item_right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
          &:nth-child(1) {
            font-weight: 400;
            font-size: 16px;
            color: #04A074;
          }
          &:nth-child(2) {
            font-weight: 400;
            font-size: 12px;
            color: #434343;
            margin-top: 11px;
          }
        }
      }
    }
  }
</style>
