<template>
    <TableLayout :permissions="['renthouse:rhrentinfo:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="装修程度" prop="decorationLevel">
                <el-select v-model="searchForm.decorationLevel" placeholder="请选择" @keypress.enter.native="search">
                    <el-option label="精装" :value="0"></el-option>
                    <el-option label="简装" :value="1"></el-option>
                    <el-option label="豪装" :value="2"></el-option>
                    <el-option label="毛坯" :value="3"></el-option>
                    <el-option label="不限" :value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="卧室数" prop="bedroomNum">
                <el-input v-model="searchForm.bedroomNum" placeholder="请输入卧室数" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="searchForm.phone" placeholder="请输入手机号" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
<!--            <ul class="toolbar" v-permissions="['renthouse:rhrentinfo:create', 'renthouse:rhrentinfo:delete']">-->
<!--                <li><el-button type="primary" @click="$refs.operaRhRentInfoWindow.open('新建求租信息')" icon="el-icon-plus" v-permissions="['renthouse:rhrentinfo:create']">新建</el-button></li>-->
<!--                <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhrentinfo:delete']">删除</el-button></li>-->
<!--            </ul>-->
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="houseTypeName" label="房屋类型" min-width="100px"></el-table-column>
                <el-table-column label="装修程度" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.decorationLevel === 0">精装</span>
                        <span v-if="row.decorationLevel === 1">简装</span>
                        <span v-if="row.decorationLevel === 2">豪装</span>
                        <span v-if="row.decorationLevel === 3">毛坯</span>
                        <span v-if="row.decorationLevel === 4">不限</span>
                    </template>
                </el-table-column>
                <el-table-column prop="bedroomNum" label="卧室数" min-width="100px"></el-table-column>
                <el-table-column prop="area" label="房屋面积" min-width="100px"></el-table-column>
                <el-table-column prop="phone" label="手机号码" min-width="100px"></el-table-column>
                <el-table-column prop="wx" label="微信号" min-width="100px"></el-table-column>
                <el-table-column label="公开联系方式" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.publicContact === 0">否</span>
                        <span v-if="row.publicContact === 1">是</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="求租地址" min-width="100px"></el-table-column>
                <el-table-column prop="description" label="描述" min-width="100px"></el-table-column>
                <el-table-column prop="createTime" label="发布时间" min-width="100px"></el-table-column>
                <el-table-column label="发布人" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.createUserInfo">{{row.createUserInfo.nickName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="containPermissions(['renthouse:rhrentinfo:update', 'renthouse:rhrentinfo:delete'])"
                    label="操作"
                    min-width="120"
                    fixed="right"
                >
                    <template slot-scope="{row}">
                        <el-button type="text" @click="$refs.operaRhRentInfoWindow.open('求租详情', row)">详情</el-button>
                        <el-button type="text" @click="deleteById(row)" v-permissions="['renthouse:rhrentinfo:delete']">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <!-- 详情 -->
        <OperaRhRentInfoWindow ref="operaRhRentInfoWindow" @success="handlePageChange"/>
    </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaRhRentInfoWindow from '@/components/rentHouse/OperaRhRentInfoWindow'
export default {
  name: 'RhRentInfo',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaRhRentInfoWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        rentalForm: '',
        houseType: '',
        decorationLevel: '',
        bedroomNum: '',
        phone: ''
      }
    }
  },
  created () {
    this.config({
      module: '求租信息',
      api: '/rentHouse/rhRentInfo',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
