<template>
    <TableLayout :permissions="['renthouse:rhhouse:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="手机号码" prop="phone">
                <el-input type="number" v-model="searchForm.phone" placeholder="请输入手机号码" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" fixed="left"></el-table-column>
                <el-table-column prop="cityName" label="城市" min-width="150px"></el-table-column>
                <el-table-column label="房源标题" min-width="200px">
                    <template slot-scope="{row}">
                        <span>{{row.address}}{{row.buildingNum}}{{row.buildingNumUnit}}{{row.roomNum}}{{row.roomNumUnit}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="出租类型" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.rentType === 0">业主</span>
                        <span v-if="row.rentType === 1">室友</span>
                        <span v-if="row.rentType === 2">转租</span>
                    </template>
                </el-table-column>
                <el-table-column label="可以短租" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.enableShortRent === 0">否</span>
                        <span v-if="row.enableShortRent === 1">是</span>
                    </template>
                </el-table-column>
                <el-table-column label="审核状态" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.reviewStatus === 0">审核中</span>
                        <span style="color: green;" v-if="row.reviewStatus === 1">已通过</span>
                        <span style="color: red;" v-if="row.reviewStatus === 2">未通过</span>
                    </template>
                </el-table-column>
                <el-table-column label="房源状态" min-width="100px">
                    <template slot-scope="{row}">
                        <span style="color: red;" v-if="row.status === 0">下架</span>
                        <span style="color: green;" v-if="row.status === 1">上架</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="小区位置" min-width="100px"></el-table-column>
                <el-table-column label="出租方式" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.rentalForm === 0">整租</span>
                        <span v-if="row.rentalForm === 1">单间</span>
                    </template>
                </el-table-column>
                <el-table-column prop="roomTypeName" label="房间类型" min-width="100px"></el-table-column>
                <el-table-column prop="reviewUserName" label="审核人" min-width="100px"></el-table-column>
                <el-table-column prop="createUserName" label="发布人" min-width="100px"></el-table-column>
                <el-table-column prop="createTime" label="发布时间" min-width="100px"></el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  export default {
    name: 'allocated',
    extends: BaseTable,
    components: { TableLayout, Pagination },
    data () {
      return {
        // 搜索
        searchForm: {
          phone: ''
        }
      }
    },
    created () {
      this.config({
        module: '已分配房源信息表',
        api: '/rentHouse/allocated',
        'field.id': 'id',
        'field.main': 'id'
      })
      this.search()
    },
  }
</script>
