import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/communityUserPage/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/communityUserPage/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/communityUserPage/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/communityUserPage/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/communityUserPage/delete/batch', {
    params: {
      ids
    }
  })
}
