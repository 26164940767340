<template>
  <TableLayout :permissions="['communityarticle:communityarticle:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="文章标题" prop="title">
        <el-input v-model="searchForm.title" placeholder="请输入文章标题" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="文章内容" prop="context">
        <el-input v-model="searchForm.context" placeholder="请输入文章内容" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="相关用户" prop="forUserId">
        <el-select
          v-model="searchForm.forUserId"
          placeholder="请选择相关用户"
          filterable
          remote
          reserve-keyword
          :remote-method="getUserList"
          :loading="userLoading"
          @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in forUserList"
            :key="itemIndex"
            :label="item.mobile"
            :value="item.id">
            <div style="display: flex;align-items: center;">
              <el-avatar shape="circle" style="width:50px;height: 50px;" fit="cover" :src="imgUrl + item.avatar"></el-avatar>
              <div style="padding-left: 8px;">
                <span><b>{{ item.username }}</b></span>
                <div>手机: {{ item.mobile }}</div>
              </div>

            </div>
          </el-option>
         </el-select>
      </el-form-item>

      <el-form-item label="审核状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择审核状态"  @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in statusList"
            :key="itemIndex"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属版块" prop="tagForSector">
        <el-select v-model="searchForm.tagForSector" placeholder="请选择所属板块"  @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in tagList"
            :key="itemIndex"
            :label="item.label"
            :value="Number(item.code)">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属标签" prop="tagList">
        <el-select
          v-model="searchForm.tagList"
          placeholder="请选择所属标签"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="remoteMethod"
          :loading="tagLoading"
         @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in textTagList"
            :key="itemIndex"
            :label="item.tagName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['communityarticle:communityarticle:create', 'communityarticle:communityarticle:delete']">
        <li><el-button type="primary" @click="$refs.operaCommunityArticleWindow.open('新建文章表')" icon="el-icon-plus" v-permissions="['communityarticle:communityarticle:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['communityarticle:communityarticle:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="" min-width="30px"></el-table-column>
        <el-table-column prop="title" label="文章标题" min-width="60px"></el-table-column>
        <el-table-column prop="forUserId" label="所属用户" min-width="100px">
          <template slot-scope="{row}">
            <el-avatar shape="circle" style="width:50px;height: 50px;flex-shrink: 0;" fit="cover" :src="imgUrl + row.forUserAvatar"></el-avatar>
            <div style="padding-left: 5px;">{{ row.forUsername || row.forNickname }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="tagForSector" label="文章所属板块" min-width="60px">
          <template v-slot="{ row }">
            {{ tagList && tagList[tagListMatch.indexOf(row.tagForSector)] && tagList[tagListMatch.indexOf(row.tagForSector)].label }}
          </template>
        </el-table-column>
        <el-table-column prop="tagList" label="文章标签" min-width="80px">
          <template slot-scope="{row}">
            <el-tag
              v-for="item in row.tagList"
                :key="item.label"
                size="mini"
                effect="plain">
              {{ item.tagName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" min-width="60px"></el-table-column>
        <el-table-column prop="likes" label="点赞量" min-width="60px"></el-table-column>
        <el-table-column prop="reviewNum" label="评论量" min-width="60px"></el-table-column>

        <el-table-column prop="pic" label="文章图片" min-width="100px">
          <template v-slot="{ row }" >
            <div v-if="row.pic">
              <el-image
                v-for="(item,itemIndex) in ((row && row.pic) || '').split(',')"
                style="width: 60px; height: 60px"
                :key="itemIndex"
                :src="imgUrl + item"
                fit="cover"
                :preview-src-list="((row && row.pic) || '').split(',').map(item=>imgUrl + item)"
              ></el-image>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="context" label="文章内容" min-width="100px">
          <template slot-scope="{row}">
            <el-popover trigger="hover" placement="top-start" width="400">
              <div style="width: 100%;overflow: auto;">
                <h3>{{ row.title }}</h3>
                <pre>{{row.context}}</pre>
              </div>
              <div slot="reference" class="ellipsis">{{row.context.substring(0,4)}}...</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['communityarticle:communityarticle:update', 'communityarticle:communityarticle:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCommunityArticleWindow.open('编辑文章表', row)" icon="el-icon-edit" v-permissions="['communityarticle:communityarticle:update']">编辑</el-button>
            <el-button type="text" @click="checkReview(row)">查看评论</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['communityarticle:communityarticle:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCommunityArticleWindow ref="operaCommunityArticleWindow" @success="handlePageChange" :tagList="tagList"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCommunityArticleWindow from '@/components/rentHouse/OperaCommunityArticleWindow'
import { findByDictCode } from '../../api/system/dictData'
import { fetchList } from '../../api/rentHouse/communityArticleTag'
import { fetchList as fetchListUser } from '../../api/system/user'
export default {
  name: 'CommunityArticle',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCommunityArticleWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        title: '',
        context: '',
        tagForSector: '',
        tagList: [],
        status: ''
      },
      imgUrl: process.env.VUE_APP_API_IMG,
      tagList: [],
      tagLoading: false,
      userLoading: false,
      textTagList: [],
      statusList: [
        { label: '待审核', value: '1' },
        { label: '已审核', value: '2' },
        { label: '已驳回', value: '3' }
      ],
      forUserList: []
    }
  },
  async created () {
    this.config({
      module: '文章表',
      api: '/rentHouse/communityArticle',
      'field.id': 'id',
      'field.main': 'id'
    })
    await this.getDictCode()
    this.search()
    this.remoteMethod()
    this.getUserList()
  },
  computed: {
    tagListMatch () {
      return this.tagList.map(item => {
        return Number(item.code)
      })
    }
  },
  watch: {
    'searchForm.tagForSector' (val) {
      this.textTagList = []
      this.searchForm.tagList = []
      this.remoteMethod()
    }

  },
  methods: {
    checkReview (row) {
      this.$router.push({
        path: '/rentHouse/communityReview',
        query: {
          articleId: row.id
        }
      })
    },
    async getUserList (keyword) {
      const res = await fetchListUser({
        page: 1,
        capacity: 50,
        model: {
          username: '',
          nickName: '',
          realname: '',
          mobile: keyword
        },
        sorts: [{ property: 'CREATE_TIME', direction: 'DESC' }]
      })

      this.forUserList = res.records
    },
    async getDictCode () {
      const tagList = await findByDictCode({
        dictCode: 'community_tag'
      })
      this.tagList = tagList
    },
    async remoteMethod (query) {
      this.tagLoading = true
      const resData = await fetchList({ page: 1, capacity: 50, model: { id: '', tagName: query, tagForSector: this.searchForm.tagForSector }, sorts: null })
      this.tagLoading = false
      this.textTagList = resData.records
    }
  }
}
</script>

<style scoped>
  .el-select-dropdown__item{
    height: auto !important;
  }
</style>
