import request from '@/utils/request'

// 查询
export function fetchList (data) {
  const postData = {
    ...data,
    model: {
      ...data.model
    }
  }
  const mapList = (data && data.model && data.model.tagList) || []
  postData.model.tagList = mapList.map((item) => {
    return { id: item, tagName: '' }
  })
  return request.post('/communityArticle/page', postData, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/communityArticle/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/communityArticle/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/communityArticle/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/communityArticle/delete/batch', {
    params: {
      ids
    }
  })
}
