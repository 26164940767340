<template>
    <GlobalWindow
      :title="title"
      width="900px"
      :withFooter="false"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
        <el-table
            :data="list"
            border
            v-loading="loading"
            style="width: 100%">
            <el-table-column
                label="房东头像">
                <template slot-scope="{row}">
                    <el-image
                        style="width: 50px; height: 50px"
                        :src="base + row.targetAvatar"
                        :preview-src-list="[base + row.targetAvatar]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column
                prop="targetUserName"
                label="房东昵称">
            </el-table-column>
            <el-table-column
                prop="targetPhone"
                label="房东手机号">
            </el-table-column>
            <el-table-column
                label="租客头像">
                <template slot-scope="{row}">
                    <el-image
                        style="width: 50px; height: 50px"
                        :src="base + row.startAvatar"
                        :preview-src-list="[base + row.startAvatar]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column
                prop="startUserName"
                label="租客昵称">
            </el-table-column>
            <el-table-column
                prop="startPhone"
                label="租客手机号">
            </el-table-column>
            <el-table-column
                label="操作">
                <template slot-scope="{row}">
                    <el-button type="text" @click="$refs.chatHistory.open('聊天记录', { chatId: row.id })">查看聊天记录</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="width: 100%; text-align: right; margin-top: 20px;">
            <el-pagination
                @current-change="changePage"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <!-- 聊天记录 -->
        <ChatHistory ref="chatHistory" />
    </GlobalWindow>
  </template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import ChatHistory from '@/components/rentHouse/chatHistory'
import { findPageNew } from '@/api/rentHouse/rhHouse'
export default {
  name: 'chatRoom',
  extends: BaseOpera,
  components: { GlobalWindow, ChatHistory },
  data () {
    return {
      // 表单数据
      form: {
        houseId: null
      },
      list: [],
      page: 1,
      capacity: 10,
      total: 0,
      loading: false,
      base: process.env.VUE_APP_API_IMG
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.list = []
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getList()
      })
    },
    changePage (e) {
      this.page = e
      this.getList()
    },
    getList () {
      this.loading = true
      findPageNew({
        capacity: this.capacity,
        page: this.page,
        model: {
          houseId: this.form.houseId
        }
      }).then(res => {
        this.list = res.records
        this.total = res.total
        this.loading = false
      })
    }
  }
}
</script>
