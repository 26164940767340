<template>
  <TableLayout :permissions="['renthouse:rhcommonphrases:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="类型名称" prop="typeName">
        <el-input v-model="searchForm.typeName" placeholder="请输入类型名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['renthouse:rhcommonphrases:create']">
        <li><el-button type="primary" @click="$refs.operaRhCommonPhrasesWindow.open('新建常用语集合表')" icon="el-icon-plus" v-permissions="['renthouse:rhcommonphrases:create']">新建</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
      >
        <el-table-column prop="typeName" label="类型名称" min-width="100px"></el-table-column>
        <el-table-column prop="statemenCollection" label="语句集合" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['renthouse:rhcommonphrases:update', 'renthouse:rhcommonphrases:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaRhCommonPhrasesWindow.open('编辑常用语集合表', row)" icon="el-icon-edit" v-permissions="['renthouse:rhcommonphrases:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['renthouse:rhcommonphrases:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaRhCommonPhrasesWindow ref="operaRhCommonPhrasesWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaRhCommonPhrasesWindow from '@/components/rentHouse/OperaRhCommonPhrasesWindow'
  export default {
    name: 'RhCommonPhrases',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaRhCommonPhrasesWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          typeName: ''
        }
      }
    },
    created () {
      this.config({
        module: '常用语集合表',
        api: '/rentHouse/rhCommonPhrases',
        'field.id': 'id',
        'field.main': 'id'
      })
      this.search()
    }
  }
</script>
