<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="名字" prop="name">
        <el-input v-model="form.name" placeholder="请输入名字" v-trim/>
      </el-form-item>
      <el-form-item label="学校" prop="school">
        <el-input v-model="form.school" placeholder="请输入学校" v-trim/>
      </el-form-item>
      <el-form-item label="简介" prop="introduction">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 12}" v-model="form.introduction" placeholder="请输入简介" v-trim/>
      </el-form-item>
      <el-form-item label="所在地" prop="location">
        <el-input v-model="form.location" placeholder="请输入所在地" v-trim/>
      </el-form-item>
      <el-form-item label="单位地址" prop="unitAddress">
        <el-input v-model="form.unitAddress" placeholder="请输入单位地址" v-trim/>
      </el-form-item>
      <el-form-item label="职业" prop="career">
        <el-input v-model="form.career" placeholder="请输入职业" v-trim/>
      </el-form-item>
      <el-form-item label="相关用户" prop="forUserId">
        <el-select
          v-model="form.forUserId"
          placeholder="请选择相关用户"
          filterable
          remote
          reserve-keyword
          :remote-method="getUserList"
          :loading="tagLoading"
         @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in forUserList"
            :key="itemIndex"
            :label="item.mobile"
            :value="item.id">
            <div style="display: flex;align-items: center;">
              <el-avatar shape="circle" style="width:50px;height: 50px;" fit="cover" :src="imgUrl + item.avatar"></el-avatar>
              <div style="padding-left: 8px;">
                <span><b>{{ item.username }}</b></span>
                <div>手机: {{ item.mobile }}</div>
              </div>

            </div>
          </el-option>
         </el-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList } from '../../api/system/user'
export default {
  name: 'OperaCommunityUserPageWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        school: '',
        introduction: '',
        location: '',
        unitAddress: '',
        career: '',
        forUserId: ''
      },
      imgUrl: process.env.VUE_APP_API_IMG,
      forUserList: [],
      tagLoading: false,
      // 验证规则
      rules: {
        forUserId: [
          { required: true, message: '请输入system_user.id' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/communityUserPage',
      'field.id': 'id'
    })
    const extendsOpen = this.open.bind(this)
    this.open = (title, target) => {
      extendsOpen(title, target)
      this.getUserList('', (target && target.forUserId) || '')
    }
  },
  methods: {
    async getUserList (keyword, defaultUserId = '') {
      const res = await fetchList({
        page: 1,
        capacity: 50,
        model: {
          id: defaultUserId,
          username: '',
          nickName: '',
          realname: '',
          mobile: keyword
        },
        sorts: [{ property: 'CREATE_TIME', direction: 'DESC' }]
      })

      this.forUserList = res.records
    }
  }
}
</script>

<style scoped>
  .el-select-dropdown__item{
    height: auto !important;
  }
</style>
