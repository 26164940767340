import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/renthouse/rhAppointShot/page', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/renthouse/rhAppointShot/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/renthouse/rhAppointShot/delete/${id}`)
}
