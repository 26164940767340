<template>
    <TableLayout :permissions="['renthouse:rhfeeback:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="房屋主键ID" prop="houseId">
                <el-input v-model="searchForm.houseId" placeholder="请输入房屋主键ID" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
            >
                <el-table-column prop="feedBackReason" label="反馈内容" min-width="100px"></el-table-column>
                <el-table-column prop="description" label="房源反馈内容" min-width="100px"></el-table-column>
                <el-table-column label="反馈类型">
                    <template slot-scope="{row}">
                        <span v-if="row.houseId">房源反馈</span>
                        <span v-else>平台反馈</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="反馈时间"></el-table-column>
                <el-table-column prop="createUserName" label="反馈人"></el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                >
                    <template slot-scope="{row}">
                        <el-button type="text" v-if="row.rhHouse" @click="$refs.operaRhHouseWindow.open('房源详情', {...row.rhHouse, seeType: 'desc'})">查看房源</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <OperaRhHouseWindow ref="operaRhHouseWindow" @success="handlePageChange"/>
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaRhHouseWindow from '@/components/rentHouse/OperaRhHouseWindow'
  export default {
    name: 'RhFeeBack',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaRhHouseWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          houseId: ''
        }
      }
    },
    created () {
      this.config({
        module: '房源反馈信息表',
        api: '/rentHouse/rhFeeBack',
        'field.id': 'id',
        'field.main': 'id',
        sorts: [{
          property: 'CREATE_TIME',
          direction: 'DESC'
        }]
      })
      this.search()
    }
  }
</script>
