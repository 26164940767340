<template>
    <TableLayout :permissions="['renthouse:rhservicecategories:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="服务名称" prop="serviceName">
                <el-input v-model="searchForm.serviceName" placeholder="请输入服务名称" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <ul class="toolbar" v-permissions="['renthouse:rhservicecategories:create']">
                <li><el-button type="primary" @click="$refs.operaRhServiceCategoriesWindow.open('新建增值服务类型')" icon="el-icon-plus" v-permissions="['renthouse:rhservicecategories:create']">新建</el-button></li>
            </ul>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
            >
                <el-table-column prop="serviceCode" label="服务的码值" min-width="100px"></el-table-column>
                <el-table-column prop="serviceName" label="服务名称" min-width="100px"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" min-width="100px"></el-table-column>
                <el-table-column
                    v-if="containPermissions(['renthouse:rhservicecategories:update', 'renthouse:rhservicecategories:delete'])"
                    label="操作"
                    min-width="120"
                    fixed="right"
                >
                    <template slot-scope="{row}">
                        <el-button type="text" @click="$refs.operaRhServiceCategoriesWindow.open('编辑增值服务类型', row)" icon="el-icon-edit" v-permissions="['renthouse:rhservicecategories:update']">编辑</el-button>
                        <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['renthouse:rhservicecategories:delete']">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <!-- 新建/修改 -->
        <OperaRhServiceCategoriesWindow ref="operaRhServiceCategoriesWindow" @success="handlePageChange"/>
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaRhServiceCategoriesWindow from '@/components/rentHouse/OperaRhServiceCategoriesWindow'
  export default {
    name: 'RhServiceCategories',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaRhServiceCategoriesWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          serviceName: ''
        }
      }
    },
    created () {
      this.config({
        module: '增值服务类型',
        api: '/rentHouse/rhServiceCategories',
        'field.id': 'id',
        'field.main': 'id'
      })
      this.search()
    }
  }
</script>
