<template>
    <TableLayout :permissions="['renthouse:rhrentalgroup:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="租房群名称" prop="rentalGroupName">
          <el-input v-model="searchForm.rentalGroupName" placeholder="请输入租房群名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['renthouse:rhrentalgroup:create', 'renthouse:rhrentalgroup:delete']">
          <li><el-button type="primary" @click="$refs.operaRhRentalGroupWindow.open('新建租房群信息表')" icon="el-icon-plus" v-permissions="['renthouse:rhrentalgroup:create']">新建</el-button></li>
          <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhrentalgroup:delete']">删除</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="主键ID" min-width="100px"></el-table-column>
          <el-table-column prop="rentalGroupNo" label="租房群图片" min-width="100px">
            <template slot-scope="{row}">
                <el-image
                    style="width: 60px; height: 60px"
                    :src="imgUrl + row.photo"
                    fit="cover"
                    :preview-src-list="[imgUrl + row.photo]"
                ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="租房群二维码" min-width="100px">
            <template slot-scope="{row}">
                <el-image
                    style="width: 60px; height: 60px"
                    :src="imgUrl + row.rentalGroupNo"
                    fit="cover"
                    :preview-src-list="[imgUrl + row.rentalGroupNo]"
                ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="categoryName" label="分类" min-width="100px"></el-table-column>
          <el-table-column prop="rentalGroupName" label="租房群名称" min-width="100px"></el-table-column>
          <el-table-column prop="label" label="租房群标签" min-width="100px"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
          <el-table-column prop="updateTime" label="更新时间" min-width="100px"></el-table-column>
          <el-table-column
            v-if="containPermissions(['renthouse:rhrentalgroup:update', 'renthouse:rhrentalgroup:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="$refs.operaRhRentalGroupWindow.open('编辑租房群信息表', row)" icon="el-icon-edit" v-permissions="['renthouse:rhrentalgroup:update']">编辑</el-button>
              <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['renthouse:rhrentalgroup:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
      <!-- 新建/修改 -->
      <OperaRhRentalGroupWindow ref="operaRhRentalGroupWindow" @success="handlePageChange"/>
    </TableLayout>
  </template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaRhRentalGroupWindow from '@/components/rentHouse/OperaRhRentalGroupWindow'
export default {
  name: 'RhRentalGroup',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaRhRentalGroupWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        rentalGroupName: ''
      },
      imgUrl: process.env.VUE_APP_API_IMG
    }
  },
  created () {
    this.config({
      module: '租房群信息表',
      api: '/rentHouse/rhRentalGroup',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
