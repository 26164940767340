import { render, staticRenderFns } from "./communityUserPage.vue?vue&type=template&id=f4018ccc&scoped=true"
import script from "./communityUserPage.vue?vue&type=script&lang=js"
export * from "./communityUserPage.vue?vue&type=script&lang=js"
import style0 from "./communityUserPage.vue?vue&type=style&index=0&id=f4018ccc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4018ccc",
  null
  
)

export default component.exports