<template>
  <TableLayout :permissions="['communityuserpage:communityuserpage:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="关键字搜索" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入关键字" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="相关用户" prop="forUserId">
        <el-select
          v-model="searchForm.forUserId"
          placeholder="请选择相关用户"
          filterable
          remote
          reserve-keyword
          :remote-method="getUserList"
          :loading="tagLoading"
         @keypress.enter.native="search">
          <el-option
            v-for="(item,itemIndex) in forUserList"
            :key="itemIndex"
            :label="item.mobile"
            :value="item.id">
            <div style="display: flex;align-items: center;">
              <el-avatar shape="circle" style="width:50px;height: 50px;" fit="cover" :src="imgUrl + item.avatar"></el-avatar>
              <div style="padding-left: 8px;">
                <span><b>{{ item.username }}</b></span>
                <div>手机: {{ item.mobile }}</div>
              </div>

            </div>
          </el-option>
         </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['communityuserpage:communityuserpage:create', 'communityuserpage:communityuserpage:delete']">
        <li><el-button type="primary" @click="$refs.operaCommunityUserPageWindow.open('新建')" icon="el-icon-plus" v-permissions="['communityuserpage:communityuserpage:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['communityuserpage:communityuserpage:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="" min-width="100px"></el-table-column>
        <el-table-column prop="forUserId" label="相关用户" min-width="100px">
          <template slot-scope="{row}">
            <el-avatar shape="circle" style="width:50px;height: 50px;flex-shrink: 0;" fit="cover" :src="imgUrl + row.avatar"></el-avatar>
            <div style="padding-left: 5px;">{{ row.username }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名字" min-width="100px"></el-table-column>
        <el-table-column prop="school" label="学校" min-width="100px"></el-table-column>
        <el-table-column prop="introduction" label="简介" min-width="100px">
          <template slot-scope="{row}">
            <el-popover trigger="hover" placement="top-start">
              <div>
                <h3>{{ row.name }}</h3>
                <pre>{{row.introduction}}</pre>
              </div>
              <div slot="reference" class="ellipsis">{{getIntro(row)}}...</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="location" label="所在地" min-width="100px"></el-table-column>
        <el-table-column prop="unitAddress" label="单位地址" min-width="100px"></el-table-column>
        <el-table-column prop="career" label="职业" min-width="100px"></el-table-column>

        <el-table-column
          v-if="containPermissions(['communityuserpage:communityuserpage:update', 'communityuserpage:communityuserpage:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCommunityUserPageWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['communityuserpage:communityuserpage:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['communityuserpage:communityuserpage:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCommunityUserPageWindow ref="operaCommunityUserPageWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCommunityUserPageWindow from '@/components/rentHouse/OperaCommunityUserPageWindow'
import { fetchList } from '../../api/system/user'
export default {
  name: 'CommunityUserPage',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCommunityUserPageWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        name: '',
        school: '',
        introduction: '',
        location: '',
        unitAddress: '',
        career: '',
        forUserId: ''
      },
      imgUrl: process.env.VUE_APP_API_IMG,
      tagLoading: false,
      forUserList: []
    }
  },
  created () {
    this.config({
      module: '',
      api: '/rentHouse/communityUserPage',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
    this.getUserList()
  },
  methods: {
    getIntro (item) {
      return (item.introduction || '').substring(0, 4)
    },
    async getUserList (keyword) {
      const res = await fetchList({
        page: 1,
        capacity: 50,
        model: {
          username: '',
          nickName: '',
          realname: '',
          mobile: keyword
        },
        sorts: [{ property: 'CREATE_TIME', direction: 'DESC' }]
      })

      this.forUserList = res.records
    }
  }
}
</script>
<style scoped>
  .el-select-dropdown__item{
    height: auto !important;
  }
</style>
