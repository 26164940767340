import { render, staticRenderFns } from "./publishListings.vue?vue&type=template&id=06bc698d&scoped=true"
import script from "./publishListings.vue?vue&type=script&lang=js"
export * from "./publishListings.vue?vue&type=script&lang=js"
import style0 from "./publishListings.vue?vue&type=style&index=0&id=06bc698d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bc698d",
  null
  
)

export default component.exports