<template>
    <TableLayout>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <div class="box">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="通知内容">
                        <el-input type="textarea" :rows="6" placeholder="请输入通知内容" v-model="form.content"></el-input>
                    </el-form-item>
                    <el-form-item label="发送范围">
                        <el-radio-group v-model="form.range" @change="form.cityId = ''">
                            <el-radio label="0">全部</el-radio>
                            <el-radio label="1">根据城市</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="城市" v-if="form.range === '1'">
                        <el-select v-model="form.cityId" placeholder="请选择城市">
                            <el-option
                                v-for="(item,index) in cityList"
                                :key="index"
                                :label="item.cityName"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="loading" :disabled="loading" type="primary" @click="send">发送</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </template>
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import { fetchList } from '@/api/rentHouse/rhCityInfo'
  import { rhMessageCreate } from '@/api/rentHouse/rhMessage'
  export default {
    name: 'RhChatLog',
    extends: BaseTable,
    components: { TableLayout },
    data () {
      return {
        form: {
          range: '0',
          content: '',
          cityId: ''
        },
        cityList: [],
        loading: false
      }
    },
    created () {
      fetchList({ page: -1, model:{ display: 1 } })
        .then(res => {
          this.cityList = res.records
        })
    },
    methods: {
      send () {
        if (this.loading) return
        if (!this.form.content) return this.$message.warning('通知内容不能为空！')
        if (this.form.range === '1' && !this.form.cityId) return this.$message.warning('城市不能为空！')
        this.loading = true
        rhMessageCreate({
          cityId: this.form.cityId,
          content: this.form.content
        }).then(res => {
          this.$message.success('发送成功！')
          this.form.content = ''
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
    .box {
        width: 50%;
        padding-bottom: 15px;
    }
</style>
