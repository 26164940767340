<template>
    <GlobalWindow
        :title="title"
        :visible.sync="visible"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="设备名称" prop="deviceName">
                <el-input v-model="form.deviceName" placeholder="请输入设备名称" v-trim/>
            </el-form-item>
            <el-form-item label="设别图标" prop="deviceIcon">
                <UploadAvatarImage :file="file" @uploadSuccess="uploadSuccess" />
            </el-form-item>
        </el-form>
    </GlobalWindow>
</template>

<script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  import UploadAvatarImage from '@/components/common/UploadAvatarImage'
  export default {
    name: 'OperaRhHouseDeviceConfigWindow',
    extends: BaseOpera,
    components: { GlobalWindow, UploadAvatarImage },
    data () {
      return {
        // 表单数据
        form: {
          id: null,
          deviceName: '',
          deviceIcon: ''
        },
        file: {
          imgurl: '',
          imgurlfull: ''
        },
        // 验证规则
        rules: {
          deviceName: [
            { required: true, message: '请输入设备名称', trigger: 'blur' }
          ],
          deviceIcon: [
            { required: true, message: '请上传设备图标', trigger: 'blur' }
          ]
        }
      }
    },
    created () {
      this.config({
        api: '/rentHouse/rhHouseDeviceConfig',
        'field.id': 'id'
      })
    },
    methods: {
      open (title, target) {
        this.title = title
        this.file.imgurlfull = ''
        this.visible = true
        // 新建
        if (target == null) {
          this.$nextTick(() => {
            this.$refs.form.resetFields()
            this.form[this.configData['field.id']] = null
          })
          return
        }
        // 编辑
        this.$nextTick(() => {
          for (const key in this.form) {
            this.form[key] = target[key]
            if (key === 'deviceIcon') {
              this.file.imgurlfull = process.env.VUE_APP_API_IMG + target[key]
            }
          }
        })
        console.log(this.file)
      },
      uploadSuccess (res) {
        this.form.deviceIcon = res.imgurl
        this.file.imgurlfull = res.imgurlfull
      }
    }
  }
</script>
