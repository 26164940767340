import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/pc/rhHouse/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/pc/rhHouse/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/pc/rhHouse/updateById', data)
}

// 导入房屋信息
export function importHouse (data) {
  return request.post('/pc/rhHouse/importHouse', data)
}

// 分页查询（新）
export function findPageNew (data) {
  return request.post('/pc/rhChat/findPageNew', data)
}

// 设置取消房源类别
export function setMiddlemanLandlord (id) {
  return request.get(`/pc/rhHouse/setMiddlemanLandlord/${id}`)
}

// 删除
export function deleteById (id) {
  return request.get(`/pc/rhHouse/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/pc/rhHouse/delete/batch', {
    params: {
      ids
    }
  })
}
