<template>
    <GlobalWindow
      :title="title"
      width="900px"
      :withFooter="false"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
        <el-table
            :data="list"
            border
            v-loading="loading"
            style="width: 100%">
            <el-table-column prop="chatId" label="聊天室id" min-width="100px"></el-table-column>
                <el-table-column prop="startUserName" label="发起方" min-width="100px"></el-table-column>
                <el-table-column prop="startUserPhone" label="发起方手机号" min-width="100px"></el-table-column>
                <el-table-column prop="targetUserName" label="接收人" min-width="100px"></el-table-column>
                <el-table-column prop="targetUserPhone" label="接收人手机号" min-width="100px"></el-table-column>
                <el-table-column prop="content" label="聊天内容" min-width="100px"></el-table-column>
                <el-table-column label="类型" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.relatedType === 0">位置信息</span>
                        <span v-else-if="row.relatedType === 1">房源信息</span>
                        <span v-else-if="row.relatedType === 2">合同信息</span>
                        <span v-else-if="row.relatedType === 3">收款码</span>
                        <span v-else-if="[4,8,9,10,11,12,13,14].includes(row.relatedType)">系统消息</span>
                        <span v-else>普通聊天信息</span>
                    </template>
                </el-table-column>
                <el-table-column prop="positionJson" label="信息" min-width="100px"></el-table-column>
                <el-table-column label="关联种类" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.relatedType === 1">房源信息</span>
                        <span v-if="row.relatedType === 0">位置信息</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="发送时间" min-width="100px"></el-table-column>
        </el-table>
        <div style="width: 100%; text-align: right; margin-top: 20px;">
            <el-pagination
                @current-change="changePage"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
    </GlobalWindow>
  </template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList } from '@/api/rentHouse/rhChatLog'
export default {
  name: 'chatHistory',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        chatId: null
      },
      list: [],
      page: 1,
      capacity: 10,
      total: 0,
      loading: false,
      base: process.env.VUE_APP_API_IMG
    }
  },
  methods: {
    open (title, target) {
      this.title = title
      this.list = []
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getList()
      })
    },
    changePage (e) {
      this.page = e
      this.getList()
    },
    getList () {
      this.loading = true
      fetchList({
        capacity: this.capacity,
        page: this.page,
        model: {
          chatId: this.form.chatId
        }
      }).then(res => {
        this.list = res.records
        this.total = res.total
        this.loading = false
      })
    }
  }
}
</script>
